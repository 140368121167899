export const types = {
    W00F0010_GET_DATABASENAME: 'W00F0010_GET_DATABASENAME',
    W00F0010_GET_DATABASENAME_SUCCESS: 'W00F0010_GET_DATABASENAME_SUCCESS',
    W00F0010_GET_LISTROLES: 'W00F0010_GET_LISTROLES',
    W00F0010_GET_LISTROLES_SUCCESS: 'W00F0010_GET_LISTROLES_SUCCESS',
    W00F0010_GET_PERMISSIONINFO: 'W00F0010_GET_PERMISSIONINFO',
    W00F0010_GET_USERSINFO: 'W00F0010_GET_USERSINFO',
    W00F0010_GET_COMPANY: 'W00F0010_GET_COMPANY',
    W00F0010_GET_COMPANY_SUCCESS: 'W00F0010_GET_COMPANY_SUCCESS',
    W00F0010_GET_ROLEINFO: 'W00F0010_GET_ROLEINFO',
    W00F0010_GET_ROLEINFO_SUCCESS: 'W00F0010_GET_ROLEINFO_SUCCESS',
    W00F0010_ADD_ROLE: 'W00F0010_ADD_ROLE',
    W00F0010_CHECK_EDIT_ROLE: 'W00F0010_CHECK_EDIT_ROLE',
    W00F0010_EDIT_ROLE: 'W00F0010_EDIT_ROLE',
    W00F0010_GET_MODULES: 'W00F0010_GET_MODULES',
    W00F0010_GET_MODULES_SUCCESS: 'W00F0010_GET_MODULES_SUCCESS',
    W00F0010_GET_ROLESPERMISSION: 'W00F0010_GET_ROLESPERMISSION',
    W00F0010_GET_ROLESPERMISSION_SUCCESS: 'W00F0010_GET_ROLESPERMISSION_SUCCESS',
    W00F0010_GET_PERMISSION: 'W00F0010_GET_PERMISSION',
    W00F0010_GET_PERMISSION_SUCCESS: 'W00F0010_GET_PERMISSION_SUCCESS',
    W00F0010_GET_PROJECTS: 'W00F0010_GET_PROJECTS',
    W00F0010_GET_PROJECTS_SUCCESS: 'W00F0010_GET_PROJECTS_SUCCESS',
    W00F0010_GET_SALARYGROUPS: 'W00F0010_GET_SALARYGROUPS',
    W00F0010_GET_SALARYGROUPS_SUCCESS: 'W00F0010_GET_SALARYGROUPS_SUCCESS',
    W00F0010_GET_ORGANIZATIONAL: 'W00F0010_GET_ORGANIZATIONAL',
    W00F0010_GET_ORGANIZATIONAL_SUCCESS: 'W00F0010_GET_ORGANIZATIONAL_SUCCESS',
    W00F0010_GET_CBO_USER_ROLES: 'W00F0010_GET_CBO_USER_ROLES',
    W00F0010_GET_CBO_USER_ROLES_SUCCESS: 'W00F0010_GET_CBO_USER_ROLES_SUCCESS',
    W00F0010_SAVE_GRIDRP: 'W00F0010_SAVE_GRIDRP',
    W00F0010_SAVE_USER_ROLES: 'W00F0010_SAVE_USER_ROLES',
    W00F0010_LOAD_CBO_TRANSACTION: 'W00F0010_LOAD_CBO_TRANSACTION',
    W00F0010_GET_LIST_USER: "W00F0010_GET_LIST_USER",
    W00F0010_GET_LIST_SCREEN: "W00F0010_GET_LIST_SCREEN",
    W00F0010_SAVE_PASTES_ROLE: "W00F0010_SAVE_PASTES_ROLE",
};

export function getCboDatabaseName(params,cb) {
    return{
        type: types.W00F0010_GET_DATABASENAME,
        params,
        cb
    }
}

export function getListRoles(params, cb) {
    return{
        type: types.W00F0010_GET_LISTROLES,
        params,
        cb
    }
}

export function getPermissionInfo(params, cb) {
    return{
        type: types.W00F0010_GET_PERMISSIONINFO,
        params,
        cb
    }
}

export function getUsersInfo(params, cb) {
    return{
        type: types.W00F0010_GET_USERSINFO,
        params,
        cb
    }
}

export function getCompany(params, cb) {
    return{
        type: types.W00F0010_GET_COMPANY,
        params,
        cb
    }
}

export function getRoleInfo(params, cb) {
    return{
        type: types.W00F0010_GET_ROLEINFO,
        params,
        cb
    }
}

export function addRoleW00F0010(params, cb) {
    return{
        type: types.W00F0010_ADD_ROLE,
        params,
        cb
    }
}

export function checkEditRoleW00F0010(params, cb) {
    return{
        type: types.W00F0010_CHECK_EDIT_ROLE,
        params,
        cb
    }
}

export function editRoleW00F0010(params, cb) {
    return{
        type: types.W00F0010_EDIT_ROLE,
        params,
        cb
    }
}

//W00F0010 Roles Permission..
//get modules dropdown..
export function getCboModuleW00F0010(params, cb) {
    return{
        type: types.W00F0010_GET_MODULES,
        params,
        cb
    }
}

//get combo permission
export function getCboPermissionW00F0010(params, cb) {
    return{
        type: types.W00F0010_GET_PERMISSION,
        params,
        cb
    }
}

//get projects
export function getProjectsW00F0010(params, cb) {
    return{
        type: types.W00F0010_GET_PROJECTS,
        params,
        cb
    }
}

//get salary groups
export function getSalaryGroupsW00F0010(params, cb) {
    return{
        type: types.W00F0010_GET_SALARYGROUPS,
        params,
        cb
    }
}

//get salary groups
export function getOrganizationalW00F0010(params, cb) {
    return{
        type: types.W00F0010_GET_ORGANIZATIONAL,
        params,
        cb
    }
}

//get cbo user roles..
export function getCboUserRolesW00F0010(cb) {
    return{
        type: types.W00F0010_GET_CBO_USER_ROLES,
        cb
    }
}

//get grid roles permission
export function getGridRPW00F0010(params, cb) {
    return{
        type: types.W00F0010_GET_ROLESPERMISSION,
        params,
        cb
    }
}

//save grid permission..
export function saveGridRPW00F0010(params, cb) {
    return{
        type: types.W00F0010_SAVE_GRIDRP,
        params,
        cb
    }
}

//save user roles..
export function saveUserRolesW00F0010(params, cb) {
    return{
        type: types.W00F0010_SAVE_USER_ROLES,
        params,
        cb
    }
}

export function getListUser(params,cb) {
    return{
        type: types.W00F0010_GET_LIST_USER,
        params,
        cb
    }
}

export function getListScreen(params,cb) {
    return{
        type: types.W00F0010_GET_LIST_SCREEN,
        params,
        cb
    }
}

export function savePastesRole(params,cb) {
    return{
        type: types.W00F0010_SAVE_PASTES_ROLE,
        params,
        cb
    }
}
//load cbo transaction..
export function loadCboTransaction(params, cb) {
    return{
        type: types.W00F0010_LOAD_CBO_TRANSACTION,
        params,
        cb
    }
}
