/**
 * @copyright 2020 @ DigiNet
 * @author THIEN
 * @create 3/18/2020
 * @Example
 */
export const types = {
  W09F1500_GET_DATA_GRID: "W09F1500_GET_DATA_GRID",
  W09F1500_GET_DATA_GRID_SUCCESS: "W09F1500_GET_DATA_GRID_SUCCESS",

  W09F1500_LOAD_CBO_DIRECT_MANAGER: "W09F1500_LOAD_CBO_DIRECT_MANAGER",
  W09F1500_LOAD_CBO_DIRECT_MANAGER_SUCCESS: "W09F1500_LOAD_CBO_DIRECT_MANAGER_SUCCESS",
  
  W09F1500_DELETE_DATA: "W09F1500_DELETE_DATA",
};

export function getDataGrid(params, cb) {
  return {
    type: types.W09F1500_GET_DATA_GRID,
    params,
    cb
  };
}

export function getCboDirectManagers(params, cb) {
  return {
    type: types.W09F1500_LOAD_CBO_DIRECT_MANAGER,
    params,
    cb
  };
}

export function deleteDataGrid(params, cb) {
  return {
    type: types.W09F1500_DELETE_DATA,
    params,
    cb
  };
}