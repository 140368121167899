/**
 * @copyright 2019 @ DigiNet
 * @author THIEN
 * @create 12/26/2019
 * @Example
 */

import Api from "../../../services/api";
import { put, cancel, take, takeLatest } from "redux-saga/effects";
import { types } from "./W09F2005_actions";
import { delay } from "redux-saga";

export default function W09F2005Sagas() {
  return [watchGetListEmployees(),watchGetTabStatus()];
}

export function* getListEmployees(data) {
  try {
    yield delay(300);

    yield put({
      type: types.W09F2005_GET_LISTEMPLOYEES + "_SUCCESS",
      data: [],
    });
    const res = yield Api.put("/w09f2005/load-data-list-employee", data.params);
    if (res && res.data) {
      yield put({
        type: types.W09F2005_GET_LISTEMPLOYEES + "_SUCCESS",
        data: res.data,
      });
      data.cb && data.cb(null, res.data);
      return;
    } else { data.cb && data.cb(res, null) }
  } catch (e) {
    data.cb && data.cb(null, null);
  }
}

export function* getTabStatus(data) {
  try {
    yield delay(300);
    const res = yield Api.put("/w09f2005/load-tab-status", data.params);
    if (res) {
      yield put({
        type: types.W09F2005_GET_TABSTATUS + "_SUCCESS",
        data: res.data,
      });
      data.cb && data.cb(null, res.data);
    } else {
      data.cb && data.cb(res, null);
    }
  } catch (e) {
    data.cb && data.cb(null, null);
  }
}

export function* watchGetListEmployees() {
  while (true) {
    const watcher = yield takeLatest(
      types.W09F2005_GET_LISTEMPLOYEES,
      getListEmployees
    );
    yield take(["LOGOUT", "NETWORK"]);
    yield cancel(watcher);
  }
}

export function* watchGetTabStatus() {
  while (true) {
    const watcher = yield takeLatest(
      types.W09F2005_GET_TABSTATUS,
      getTabStatus
    );
    yield take(["LOGOUT", "NETWORK"]);
    yield cancel(watcher);
  }
}
