
export const types = {
    W09F0001_GET_DATAATTACHMENTS: 'W09F0001_GET_DATAATTACHMENTS',
    W09F0001_SAVE_ATTACHMENT: 'W09F0001_SAVE_ATTACHMENT',
    W09F0001_DELETE_ATTACHMENT: 'W09F0001_DELETE_ATTACHMENT',
};

export function getDataAttachments(params,cb) {
    return{
        type: types.W09F0001_GET_DATAATTACHMENTS,
        params,
        cb
    }
}

export function saveAttachment(params,cb) {
    return{
        type: types.W09F0001_SAVE_ATTACHMENT,
        params,
        cb
    }
}

export function deleteAttachment(params,cb) {
    return{
        type: types.W09F0001_DELETE_ATTACHMENT,
        params,
        cb
    }
}