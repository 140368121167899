/**
 * @copyright 2020 @ DigiNet
 * @author DINHTHIEN
 * @create 7/9/2020
 * @Example
 */

import { types } from "./W09F2021_actions";
const initialState = {
    getForm: [],
};

export default function (state = initialState, action = {}) {
    switch (action.type) {
        case types.W09F2021_LOAD_FORM_SUCCESS: {
            return {
                ...state,
                getForm: action.data,
            };
        }
        default:
            return state;
    }
}
