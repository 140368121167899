/**
 * @copyright 2020 @ DigiNet
 * @author DINHTHIEN
 * @create 2/6/2020
 * @Example
 */

import Api from "../../../services/api";
import { cancel, put, take, takeLatest } from "redux-saga/effects";
import { types } from "./W09F1500_actions";
import { delay } from "redux-saga";

export default function W09F2060Sagas() {
  return [watchGetDataGrid(), watchGetCboDirectManager(), watchDeleteData()];
}

export function* getDataGrid(data) {
  try {
    yield delay(300);
    const response = yield Api.put("/w09f1500/load-form-query", data.params);
    if (response && response.data) {
      yield put({
        type: types.W09F1500_GET_DATA_GRID_SUCCESS,
        data: response.data
      });
      data.cb && data.cb(null, response.data);
    } else {
      data.cb && data.cb(response, null);
    }
  } catch (e) {
    console.log("get data grid error");
  }
}

export function* watchGetDataGrid() {
  while (true) {
    const watcher = yield takeLatest(types.W09F1500_GET_DATA_GRID, getDataGrid);
    yield take(["LOGOUT", "NETWORK"]);
    yield cancel(watcher);
  }
}

export function* getCboDirectManagers(data) {
  try {
    yield delay(300);
    const response = yield Api.put("/w09f1500/load-direct-manager", data.params);
    if (response && response.data) {
      yield put({
        type: types.W09F1500_LOAD_CBO_DIRECT_MANAGER_SUCCESS,
        data: response.data
      });
      data.cb && data.cb(null, response.data);
    } else {
      data.cb && data.cb(response, null);
    }
  } catch (e) {
    console.log("get combo directmanager error");
  }
}

export function* watchGetCboDirectManager() {
  while (true) {
    const watcher = yield takeLatest(types.W09F1500_LOAD_CBO_DIRECT_MANAGER, getCboDirectManagers);
    yield take(["LOGOUT", "NETWORK"]);
    yield cancel(watcher);
  }
}

export function* deleteData(data) {
  try {
    yield delay(300);
    const response = yield Api.put("/w09f1500/del", data.params);
    if (response && response.data) {
      data.cb && data.cb(null, response.data);
    } else {
      data.cb && data.cb(response, null);
    }
  } catch (e) {
    console.log("delete data error");
  }
}

export function* watchDeleteData() {
  while (true) {
    const watcher = yield takeLatest(types.W09F1500_DELETE_DATA, deleteData);
    yield take(["LOGOUT", "NETWORK"]);
    yield cancel(watcher);
  }
}
