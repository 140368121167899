/**
 * @copyright 2019 @ DigiNet
 * @author DINHTHIEN
 * @create 3/23/2020
 * @Example
 */

import Api from "../../../services/api";
import { cancel, put, take, takeLatest } from "redux-saga/effects";
import { types } from "./W09F1501_actions";
import { delay } from "redux-saga";

export default function W09F1501Sagas() {
  return [
    watchGetForm(),
    watchGetCboGender(),
    watchGetCboPositions(),
    watchGetCboDirectManagers(),
    watchGetCboProvinces(),
    watchAdd(),
    watchEdit()
  ];
}

export function* getForm(data) {
  try {
    yield delay(300);
    const response = yield Api.put("/w09f1501/load-form", data.params);
    if (response && response.data) {
      yield put({ type: types.W09F1501_GET_FORM_SUCCESS, data: response.data });
      data.cb && data.cb(null, response.data);
    } else {
      data.cb && data.cb(response, null);
    }
  } catch (e) {
    console.log("get form error");
  }
}

export function* watchGetForm() {
  while (true) {
    const watcher = yield takeLatest(types.W09F1501_GET_FORM, getForm);
    yield take(["LOGOUT", "NETWORK"]);
    yield cancel(watcher);
  }
}

export function* getCboGenders(data) {
  try {
    yield delay(300);
    const response = yield Api.put("/w09f1501/get-genders", data.params);
    if (response && response.data) {
      yield put({ type: types.W09F1501_LOAD_CBO_GENDERS_SUCCESS, data: response.data });
      data.cb && data.cb(null, response.data);
    } else {
      data.cb && data.cb(response, null);
    }
  } catch (e) {
    console.log("get form error");
  }
}

export function* watchGetCboGender() {
  while (true) {
    const watcher = yield takeLatest(types.W09F1501_LOAD_CBO_GENDERS, getCboGenders);
    yield take(["LOGOUT", "NETWORK"]);
    yield cancel(watcher);
  }
}

export function* getCboProvinces(data) {
  try {
    yield delay(300);
    const response = yield Api.get("/w09f1501/get-provinces", data.params);
    if (response && response.data) {
      yield put({ type: types.W09F1501_LOAD_CBO_PROVINCES_SUCCESS, data: response.data });
      data.cb && data.cb(null, response.data);
    } else {
      data.cb && data.cb(response, null);
    }
  } catch (e) {
    console.log("get provinces error");
  }
}

export function* watchGetCboProvinces() {
  while (true) {
    const watcher = yield takeLatest(types.W09F1501_LOAD_CBO_PROVINCES, getCboProvinces);
    yield take(["LOGOUT", "NETWORK"]);
    yield cancel(watcher);
  }
}

export function* getCboDirectManagers(data) {
  try {
    yield delay(300);
    const response = yield Api.put("/w09f1501/get-direct-managers", data.params);
    if (response && response.data) {
      yield put({
        type: types.W09F1501_LOAD_CBO_DIRECT_MANAGERS_SUCCESS,
        data: response.data
      });
      data.cb && data.cb(null, response.data);
    } else {
      data.cb && data.cb(response, null);
    }
  } catch (e) {
    console.log("get direct managers error");
  }
}

export function* watchGetCboDirectManagers() {
  while (true) {
    const watcher = yield takeLatest(
      types.W09F1501_LOAD_CBO_DIRECT_MANAGERS,
      getCboDirectManagers
    );
    yield take(["LOGOUT", "NETWORK"]);
    yield cancel(watcher);
  }
}

export function* getCboPositions(data) {
  try {
    yield delay(300);
    const response = yield Api.get("/w09f1501/get-positions", data.params);
    if (response && response.data) {
      yield put({ type: types.W09F1501_LOAD_CBO_POSITIONS_SUCCESS, data: response.data });
      data.cb && data.cb(null, response.data);
    } else {
      data.cb && data.cb(response, null);
    }
  } catch (e) {
    console.log("get positions error");
  }
}

export function* watchGetCboPositions() {
  while (true) {
    const watcher = yield takeLatest(types.W09F1501_LOAD_CBO_POSITIONS, getCboPositions);
    yield take(["LOGOUT", "NETWORK"]);
    yield cancel(watcher);
  }
}

export function* add(data) {
  try {
    yield delay(300);
    const response = yield Api.put("/w09f1501/insert-record", data.params);
    if (response && response.data) {
      data.cb && data.cb(null, response.data);
    } else {
      data.cb && data.cb(response, null);
    }
  } catch (e) {
    console.log("add error");
  }
}

export function* watchAdd() {
  while (true) {
    const watcher = yield takeLatest(types.W09F1501_ADD, add);
    yield take(["LOGOUT", "NETWORK"]);
    yield cancel(watcher);
  }
}

export function* edit(data) {
  try {
    yield delay(300);
    const response = yield Api.put("/w09f1501/edit-record", data.params);
    if (response && response.data) {
      data.cb && data.cb(null, response.data);
    } else {
      data.cb && data.cb(response, null);
    }
  } catch (e) {
    console.log("edit error");
  }
}

export function* watchEdit() {
  while (true) {
    const watcher = yield takeLatest(types.W09F1501_EDIT, edit);
    yield take(["LOGOUT", "NETWORK"]);
    yield cancel(watcher);
  }
}
