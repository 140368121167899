/**
 * @copyright 2023 @ DigiNet
 * @author TRANGHOANG
 * @create 8/3/2023
 * @Example
 */

export const types = {
    W09F1121_LOAD_TEMPLATE: "W09F1121_LOAD_TEMPLATE",
    W09F1121_LOAD_GRID: "W09F1121_LOAD_GRID",
    W09F1121_SAVE: "W09F1121_SAVE",
};

export function loadTemplate(params, cb) {
    return {
        type: types.W09F1121_LOAD_TEMPLATE,
        params,
        cb,
    };
}

export function loadGrid(params, cb) {
    return {
        type: types.W09F1121_LOAD_GRID,
        params,
        cb,
    };
}

export function save(params, cb) {
    return {
        type: types.W09F1121_SAVE,
        params,
        cb,
    };
}
