/**
 * @copyright 2019 @ DigiNet
 * @author THIEN
 * @create 12/5/2019
 * @Example
 */
export const types = {
  W09F2005_GET_LISTEMPLOYEES: "W09F2005_GET_LISTEMPLOYEES",
  W09F2005_GET_LISTEMPLOYEES_SUCCESS: "W09F2005_GET_LISTEMPLOYEES_SUCCESS",
  W09F2005_GET_TABSTATUS: "W09F2005_GET_TABSTATUS",
  W09F2005_GET_TABSTATUS_SUCCESS: "W09F2005_GET_TABSTATUS_SUCCESS"
};

export function getListEmployees(params, cb) {
  return {
    type: types.W09F2005_GET_LISTEMPLOYEES,
    params,
    cb
  };
}

export function getTabStatus(params, cb) {
  return { type: types.W09F2005_GET_TABSTATUS, params, cb };
}
