/**
 * @copyright 2019 @ DigiNet
 * @author NAMDANG
 * @create 1/3/2023
 * @Example
 */

import Api from "../../../services/api";
import { cancel, take, takeLatest } from "redux-saga/effects";
import { types } from "./W09F1121_actions";
import { delay } from "redux-saga";

export default function W09F1121Sagas() {
    return [
        watchLoadGrid(),
        watchLoadTemplate(),
        watchSave(),
    ];
}

export function* loadGrid(data) {
    try {
        yield delay(300);
        const response = yield Api.put("/w09f1121/load-grid", data.params);
        if (response && response.data) {
            data.cb && data.cb(null, response.data);
        } else {
            data.cb && data.cb(response, null);
        }
    } catch (e) {
        console.log("load grid error");
    }
}

export function* watchLoadGrid() {
    while (true) {
        const watcher = yield takeLatest(types.W09F1121_LOAD_GRID, loadGrid);
        yield take(["LOGOUT", "NETWORK"]);
        yield cancel(watcher);
    }
}

export function* loadTemplate(data) {
    try {
        yield delay(300);
        const response = yield Api.put("/w09f1121/load-template", data.params);
        if (response && response.data) {
            data.cb && data.cb(null, response.data);
        } else {
            data.cb && data.cb(response, null);
        }
    } catch (e) {
        console.log("load template error");
    }
}

export function* watchLoadTemplate() {
    while (true) {
        const watcher = yield takeLatest(types.W09F1121_LOAD_TEMPLATE, loadTemplate);
        yield take(["LOGOUT", "NETWORK"]);
        yield cancel(watcher);
    }
}

export function* save(data) {
    try {
        yield delay(300);
        const response = yield Api.post("/w09f1121/save", data.params);
        if (response && response.data) {
            data.cb && data.cb(null, response.data);
        } else {
            data.cb && data.cb(response, null);
        }
    } catch (e) {
        console.log("save error");
    }
}

export function* watchSave() {
    while (true) {
        const watcher = yield takeLatest(types.W09F1121_SAVE, save);
        yield take(["LOGOUT", "NETWORK"]);
        yield cancel(watcher);
    }
}