
export const types = {
    W09F2000_GET_INFO: 'W09F2000_GET_INFO',
    W09F2000_GET_INFO_SUCCESS: 'W09F2000_GET_INFO_SUCCESS',
    W09F2000_GET_LEAVE_AMOUNT: 'W09F2000_GET_LEAVE_AMOUNT',
};

export function getInfo(params, cb) {
    return{
        type: types.W09F2000_GET_INFO,
        params,
        cb
    }
}

export function getLeaveAmount(params, cb) {
    return{
        type: types.W09F2000_GET_LEAVE_AMOUNT,
        params,
        cb
    }
}