/**
 * @copyright 2019 @ DigiNet
 * @author THIEN
 * @create 12/5/2019
 * @Example
 */

import { types } from "./W09F1500_actions";

const initialState = {
  getDataGrid: [],
  getCboDirectManagers: [],
};

export default function(state = initialState, action = {}) {
  switch (action.type) {
    case types.W09F1500_GET_DATA_GRID_SUCCESS: {
      return { ...state, getDataGrid: action.data };
    }
    case types.W09F1500_LOAD_CBO_DIRECT_MANAGER_SUCCESS: {
      return { ...state, getCboDirectManagers: action.data };
    }
    default: {
      return state;
    }
  }
}
