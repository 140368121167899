/**
 * @copyright 2022 @ DigiNet
 * @author TRANGHOANG
 * @create 11/04/2022
 * @Example
 */

export const types = {
    W00F1000_GET_LIST: 'W00F1000_GET_LIST',
};

export function getList(params,cb) {
    return{
        type: types.W00F1000_GET_LIST,
        params,
        cb
    }
}
