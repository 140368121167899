/**
 * @copyright 2020 @ DigiNet
 * @author DINHTHIEN
 * @create 2/6/2020
 * @Example
 */

import Api from "../../../services/api";
import { cancel, put, take, takeLatest } from "redux-saga/effects";
import { types } from "./W09F2020_actions";
import { delay } from "redux-saga";

export default function W09F2020Sagas() {
    return [watchGetFormGrid(), watchUpdateCancel()];
}

export function* getFormGrid(data) {
    try {
        yield delay(300);
        const response = yield Api.put("/w09f2020/load-grid", data.params);
        if (response && response.data) {
            yield put({ type: types.W09F2020_LOAD_FORM_GRID_SUCCESS, data: response.data });
            data.cb && data.cb(null, response.data);
        } else {
            data.cb && data.cb(response, null);
        }
    } catch (e) {
        console.log("get form grid error");
    }
}

export function* watchGetFormGrid() {
    while (true) {
        const watcher = yield takeLatest(types.W09F2020_LOAD_FORM_GRID, getFormGrid);
        yield take(["LOGOUT", "NETWORK"]);
        yield cancel(watcher);
    }
}

export function* updateCancel(data) {
    try {
        yield delay(300);
        const response = yield Api.put("/w09f2020/update", data.params);
        if (response && response.data) {
            data.cb && data.cb(null, response.data);
        } else {
            data.cb && data.cb(response, null);
        }
    } catch (e) {
        console.log("W09F2020 update cancel error");
    }
}

export function* watchUpdateCancel() {
    while (true) {
        const watcher = yield takeLatest(types.W09F2020_UPDATE_CANCEL, updateCancel);
        yield take(["LOGOUT", "NETWORK"]);
        yield cancel(watcher);
    }
}
