/**
 * @copyright 2019 @ DigiNet
 * @author NAMDANG
 * @create 1/3/2023
 * @Example
 */

export const types = {
    W09F1120_LOAD_FORM: "W09F1120_LOAD_FORM",
    W09F1120_CREATE_FOLDER: "W09F1120_CREATE_FOLDER",
    W09F1120_CHANGE_FOLDER_NAME: "W09F1120_CHANGE_FOLDER_NAME",
    W09F1120_PUBLISH: "W09F1120_PUBLISH",
    W09F1120_SHARE: "W09F1120_SHARE",
    W09F1120_DOWNLOAD: "W09F1120_DOWNLOAD",
    W09F1120_ON_DELETE: "W09F1120_ON_DELETE",
    W09F1120_SEARCH: "W09F1120_SEARCH",
};

export function loadForm(params, cb) {
    return {
        type: types.W09F1120_LOAD_FORM,
        params,
        cb,
    };
}

export function createFolder(params, cb) {
    return {
        type: types.W09F1120_CREATE_FOLDER,
        params,
        cb,
    };
}

export function changeFolderName(params, cb) {
    return {
        type: types.W09F1120_CHANGE_FOLDER_NAME,
        params,
        cb,
    };
}

export function publish(params, cb) {
    return {
        type: types.W09F1120_PUBLISH,
        params,
        cb,
    };
}

export function share(params,cb) {
    return {
        type: types.W09F1120_SHARE,
        params,
        cb,
    };
}

export function download(params, cb) {
    return {
        type: types.W09F1120_DOWNLOAD,
        params,
        cb,
    };
}

export function onDelete(params, cb) {
    return {
        type: types.W09F1120_ON_DELETE,
        params,
        cb,
    };
}

export function search(params, cb) {
    return {
        type: types.W09F1120_SEARCH,
        params,
        cb,
    };
}
