/**
 * @copyright 2019 @ DigiNet
 * @author NAMDANG
 * @create 1/3/2023
 * @Example
 */

import Api from "../../../services/api";
import { cancel, take, takeLatest } from "redux-saga/effects";
import { types } from "./W09F1120_actions";
import { delay } from "redux-saga";
import Config from "../../../config";

export default function W09F1120Sagas() {
    return [
        watchLoadForm(),
        watchCreateFolder(),
        watchChangeFolderName(),
        watchPublish(),
        watchDownload(),
        watchOnDelete(),
        watchSearch(),
        watchShare(),
    ];
}

export function* loadForm(data) {
    try {
        yield delay(300);
        const response = yield Api.put("/w09f1120/load-form", data.params);
        if (response && response.data) {
            data.cb && data.cb(null, response.data);
        } else {
            data.cb && data.cb(response, null);
        }
    } catch (e) {
        console.log("load form error");
    }
}

export function* watchLoadForm() {
    while (true) {
        const watcher = yield takeLatest(types.W09F1120_LOAD_FORM, loadForm);
        yield take(["LOGOUT", "NETWORK"]);
        yield cancel(watcher);
    }
}

export function* createFolder(data) {
    try {
        yield delay(300);
        const response = yield Api.post("/w09f1120/create-folder", data.params);
        if (response && response.data) {
            data.cb && data.cb(null, response.data);
        } else {
            data.cb && data.cb(response, null);
        }
    } catch (e) {
        console.log("create folder error");
    }
}

export function* watchCreateFolder() {
    while (true) {
        const watcher = yield takeLatest(types.W09F1120_CREATE_FOLDER, createFolder);
        yield take(["LOGOUT", "NETWORK"]);
        yield cancel(watcher);
    }
}

export function* changeFolderName(data) {
    try {
        yield delay(300);
        const response = yield Api.put("/w09f1120/change-folder-name", data.params);
        if (response && response.data) {
            data.cb && data.cb(null, response.data);
        } else {
            data.cb && data.cb(response, null);
        }
    } catch (e) {
        console.log("change folder name error");
    }
}

export function* watchChangeFolderName() {
    while (true) {
        const watcher = yield takeLatest(types.W09F1120_CHANGE_FOLDER_NAME, changeFolderName);
        yield take(["LOGOUT", "NETWORK"]);
        yield cancel(watcher);
    }
}

export function* publish(data) {
    try {
        yield delay(300);
        const response = yield Api.put("/w09f1120/publish", data.params);
        if (response && response.data) {
            data.cb && data.cb(null, response.data);
        } else {
            data.cb && data.cb(response, null);
        }
    } catch (e) {
        console.log("publish error");
    }
}

export function* watchPublish() {
    while (true) {
        const watcher = yield takeLatest(types.W09F1120_PUBLISH, publish);
        yield take(["LOGOUT", "NETWORK"]);
        yield cancel(watcher);
    }
}

export function* share(data) {
    try {
        yield delay(300);

        let url = "/w09f1120/share?";
        delete data.params.Name;
        Object.keys(data.params).forEach(key=>url+=`${key}=${data.params[key]}&`);

        const response = yield Api.get(url);
        if (response && response.data) {
            data.cb && data.cb(null, response.data);
        } else {
            data.cb && data.cb(response, null);
        }
    } catch (e) {
        console.log("share error");
    }
}

export function* watchShare() {
    while (true) {
        const watcher = yield takeLatest(types.W09F1120_SHARE, share);
        yield take(["LOGOUT", "NETWORK"]);
        yield cancel(watcher);
    }
}

function checkUrlFileName(filename) {
    //if file name had special character return valid data
    const specialChar = filename.search("UTF-8");
    if (specialChar !== -1) {
        const reFileName = filename.slice(specialChar + 7, filename.length);
        return (filename = decodeURIComponent(reFileName));
    }
    return filename; // else return original data
}

export function* download(data) {
    try {
        yield delay(300);
        const response = yield Api.putExport(`/w09f1120/download?id=${data?.params?.id}`, null, "GET");
        if (response && response.data) {
            let _res;
            if (response.type === "json") {
                _res = yield response.data.json();
                if (_res && _res.data) {
                    data.cb && data.cb(null, _res.data);
                } else {
                    data.cb && data.cb(_res, null);
                }
            } else {
                const content = yield response.data.blob();
                const _fileExt = Config.helpers.getExtFile(response.filename);
                const datetime = new Date().getTime();
                const _filename = response.filename
                    ? checkUrlFileName(response.filename)
                    : "data-export-hr-" + datetime + `.${_fileExt}`;
                _res = {
                    fileName: _filename,
                    fileExt: _fileExt,
                    url: URL.createObjectURL(content),
                };
                if (_res && _res.url) {
                    data.cb && data.cb(null, _res);
                } else {
                    data.cb && data.cb(_res, null);
                }
            }
        } else {
            data.cb && data.cb(response, null);
        }
    } catch (e) {
        console.log("download error");
    }
}

export function* watchDownload() {
    while (true) {
        const watcher = yield takeLatest(types.W09F1120_DOWNLOAD, download);
        yield take(["LOGOUT", "NETWORK"]);
        yield cancel(watcher);
    }
}
export function* onDelete(data) {
    try {
        yield delay(300);
        const response = yield Api.delete(`/w09f1120/delete`, data.params);
        if (response && response.data) {
            data.cb && data.cb(null, response.data);
        } else {
            data.cb && data.cb(response, null);
        }
    } catch (e) {
        console.log("delete error");
    }
}

export function* watchOnDelete() {
    while (true) {
        const watcher = yield takeLatest(types.W09F1120_ON_DELETE, onDelete);
        yield take(["LOGOUT", "NETWORK"]);
        yield cancel(watcher);
    }
}

export function* search(data) {
    try {
        yield delay(300);
        const response = yield Api.put(`/w09f1120/search`, data.params);
        if (response && response.data) {
            data.cb && data.cb(null, response.data);
        } else {
            data.cb && data.cb(response, null);
        }
    } catch (e) {
        console.log("search error");
    }
}

export function* watchSearch() {
    while (true) {
        const watcher = yield takeLatest(types.W09F1120_SEARCH, search);
        yield take(["LOGOUT", "NETWORK"]);
        yield cancel(watcher);
    }
}
