/**
 * @copyright 2019 @ DigiNet
 * @author TRIHAO
 * @create 2/12/2020
 * @Example
 */

import Api from "../../../services/api";
import {cancel, put, take, takeLatest} from "redux-saga/effects";
import {types} from "./W09F2010_actions";
import {delay} from "redux-saga";

export default function W09F2010Sagas() {
    return [
        watchGetDataGrid(),
        watchGetForm(),
        watchGetCboAppStatus(),
        watchDeleteData(),
        watchEditData(),
        watchSaveData(),
        watchGetCboResignFormID(),
        watchGetCboReason(),
        watchGetViolareDays(),
    ];
}

export function* getForm(data) {
    try {
        yield delay(300);
        const response = yield Api.put("/w09f2010/load-form-gird", data.params);
        if (response && response.data) {
            yield put({type: types.W09F2010_GET_FORM_SUCCESS, data: response.data});
            data.cb && data.cb(null, response.data);
        } else {
            data.cb && data.cb(response, null);
        }
    } catch (e) {
        console.log("get form error");
    }
}

export function* watchGetForm() {
    while (true) {
        const watcher = yield takeLatest(types.W09F2010_GET_FORM, getForm);
        yield take(["LOGOUT", "NETWORK"]);
        yield cancel(watcher);
    }
}

//get data grid
export function* getDataGrid(data) {
    try {
        yield delay(300);
        const response = yield Api.put("/w09f2010/load-form", data.params);
        if (response && response.data) {
            yield put({
                type: types.W09F2010_LOAD_GRID_SUCCESS,
                data: response.data
            });
            data.cb && data.cb(null, response.data);
        } else {
            data.cb && data.cb(response, null);
        }
    } catch (e) {
        console.log("get form error");
    }
}

export function* watchGetDataGrid() {
    while (true) {
        const watcher = yield takeLatest(types.W09F2010_LOAD_GRID, getDataGrid);
        yield take(["LOGOUT", "NETWORK"]);
        yield cancel(watcher);
    }
}

//get Cbo Approval Staus
export function* getCboAppStatus(data) {
    try {
        yield delay(300);
        const response = yield Api.put("/w09f2060/load-cbo-app-status", data.params);
        if (response && response.data) {
            yield put({
                type: types.W09F2010_LOAD_APPROVAL_STATUS_SUCCESS,
                data: response.data
            });
            data.cb && data.cb(null, response.data);
        } else {
            data.cb && data.cb(response, null);
        }
    } catch (e) {
        console.log("get approval status error");
    }
}

export function* watchGetCboAppStatus() {
    while (true) {
        const watcher = yield takeLatest(
            types.W09F2010_LOAD_APPROVAL_STATUS,
            getCboAppStatus
        );
        yield take(["LOGOUT", "NETWORK"]);
        yield cancel(watcher);
    }
}

export function* getCboReason(data) {
    try {
        yield delay(300);
        const response = yield Api.put("/standard/load-cbo-reason", data.params);
        if (response && response.data) {
            yield put({type: types.W09F2010_LOAD_CBO_REASON_SUCCESS, data: response.data});
            data.cb && data.cb(null, response.data);
        } else {
            data.cb && data.cb(response, null);
        }
    } catch (e) {
        console.log("get combo reason action error");
    }
}

export function* watchGetCboReason() {
    while (true) {
        const watcher = yield takeLatest(types.W09F2010_LOAD_CBO_REASON,getCboReason);
        yield take(["LOGOUT", "NETWORK"]);
        yield cancel(watcher);
    }
}

export function* getCboResignFormID(data) {
    try {
        yield delay(300);
        const response = yield Api.put("/w09f2010/load-resign-form", data.params);
        if (response && response.data) {
            yield put({type: types.W09F2010_LOAD_CBO_RESIGN_FORM_SUCCESS, data: response.data});
            data.cb && data.cb(null, response.data);
        } else {
            data.cb && data.cb(response, null);
        }
    } catch (e) {
        console.log("get combo disviolation action error");
    }
}

export function* watchGetCboResignFormID() {
    while (true) {
        const watcher = yield takeLatest(types.W09F2010_LOAD_CBO_RESIGN_FORM,getCboResignFormID);
        yield take(["LOGOUT", "NETWORK"]);
        yield cancel(watcher);
    }
}

export function* editData(data) {
    try {
        yield delay(300);
        const response = yield Api.put("/w09f2010/edit", data.params);
        if (response && response.data) {
            data.cb && data.cb(null, response.data);
        } else {
            data.cb && data.cb(response, null);
        }
    } catch (e) {
        console.log("edit data error");
    }
}

export function* watchEditData() {
    while (true) {
        const watcher = yield takeLatest(types.W09F2010_EDIT_DATA, editData);
        yield take(["LOGOUT", "NETWORK"]);
        yield cancel(watcher);
    }
}

export function* saveData(data) {
    try {
        yield delay(300);
        const response = yield Api.put("/w09f2010/add", data.params);
        if (response && response.data) {
            data.cb && data.cb(null, response.data);
        } else {
            data.cb && data.cb(response, null);
        }
    } catch (e) {
        console.log("edit data error");
    }
}

export function* watchSaveData() {
    while (true) {
        const watcher = yield takeLatest(types.W09F2010_SAVE_DATA, saveData);
        yield take(["LOGOUT", "NETWORK"]);
        yield cancel(watcher);
    }
}

//deleteData
export function* deleteData(data) {
    try {
        yield delay(300);
        const response = yield Api.put("/w09f2010/del", data.params);
        if (response && response.data) {
            data.cb && data.cb(null, response.data);
        } else {
            data.cb && data.cb(response, null);
        }
    } catch (e) {
        console.log("save absent error");
    }
}

export function* watchDeleteData() {
    while (true) {
        const watcher = yield takeLatest(types.W09F2010_DELETE_DATA, deleteData);
        yield take(["LOGOUT", "NETWORK"]);
        yield cancel(watcher);
    }
}

//get violare day
export function* getViolareDays(data) {
    try {
        yield delay(300);
        const response = yield Api.put("/w09f2010/get-violate-days", data.params);
        if (response && response.data) {
            data.cb && data.cb(null, response.data);
        } else {
            data.cb && data.cb(response, null);
        }
    } catch (e) {
        console.log("save absent error");
    }
}

export function* watchGetViolareDays() {
    while (true) {
        const watcher = yield takeLatest(types.W09F2010_GET_VIOLATEDAYS, getViolareDays);
        yield take(["LOGOUT", "NETWORK"]);
        yield cancel(watcher);
    }
}

