/**
 * @copyright 2022 @ DigiNet
 * @author TRANGHOANG
 * @create 11/04/2022
 * @Example
 */

import Api from '../../../services/api';
import {cancel, take, takeLatest} from "redux-saga/effects";
import {types} from "../../W0X/W00F1000/W00F1000_actions";
import {delay} from "redux-saga";

export default function W00F1000Sagas() {
    return [
        watchGetList(),
    ];
}

export function* getList(data) {
    try {
        yield delay(300);
        const response = yield Api.put('/w00f1000/load-grid-alert', data.params);
        if (response && response.data) {
            data.cb && data.cb(null, response.data)
        }
        else{
            data.cb && data.cb(response, null)
        }
    }
    catch (e) {
        console.log('get list user error');
    }

}

export function* watchGetList() {
    while (true){
        const watcher = yield takeLatest(types.W00F1000_GET_LIST, getList);
        yield take(['LOGOUT','NETWORK']);
        yield cancel(watcher);
    }
}