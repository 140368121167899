import {types} from "./W00F0010_actions";

const initialState = {
    getCboDatabaseName: [],
    getGridListRoles: [],
    getCompany: [],
    getRoleInfo: null,
    getCboModules: [],
    getGridRP: [],
    getPermission: [],
    getProjects: [],
    getSalaryGroups: [],
    getOrganizational: [],
    getCboUserRoles: [],
};

export default function (state = initialState, action = {}) {
    switch (action.type) {
        case types.W00F0010_GET_DATABASENAME_SUCCESS:
            return {
                ...state,
                getCboDatabaseName: action.data
            };
        case types.W00F0010_GET_LISTROLES_SUCCESS:
            return {
                ...state,
                getGridListRoles: action.data
            };
        case types.W00F0010_GET_COMPANY_SUCCESS:
            return {
                ...state,
                getCompany: action.data
            };
        case types.W00F0010_GET_ROLEINFO_SUCCESS:
            return {
                ...state,
                getRoleInfo: action.data
            };
        case types.W00F0010_GET_MODULES_SUCCESS:
            return {
                ...state,
                getCboModules: action.data
            };
        case types.W00F0010_GET_ROLESPERMISSION_SUCCESS:
            return {
                ...state,
                getGridRP: action.data
            };
        case types.W00F0010_GET_PERMISSION_SUCCESS:
            return {
                ...state,
                getPermission: action.data
            };
        case types.W00F0010_GET_PROJECTS_SUCCESS:
            return {
                ...state,
                getProjects: action.data
            };
        case types.W00F0010_GET_SALARYGROUPS_SUCCESS:
            return {
                ...state,
                getSalaryGroups: action.data
            };
        case types.W00F0010_GET_ORGANIZATIONAL_SUCCESS:
            return {
                ...state,
                getOrganizational: action.data
            };
        case types.W00F0010_GET_CBO_USER_ROLES_SUCCESS:
            return {
                ...state,
                getCboUserRoles: action.data
            };
        default:
            return state;
    }
}