/**
 * @copyright 2020 @ DigiNet
 * @author DINHTHIEN
 * @create 7/9/2020
 * @Example
 */
export const types = {
  W09F2020_LOAD_FORM_GRID: "W09F2020_LOAD_FORM_GRID",
  W09F2020_LOAD_FORM_GRID_SUCCESS: "W09F2020_LOAD_FORM_GRID_SUCCESS",
  W09F2020_UPDATE_CANCEL: "W09F2020_UPDATE_CANCEL",
};

export function getFormGrid(params, cb) {
  return {
    type: types.W09F2020_LOAD_FORM_GRID,
    params,
    cb
  };
};

export function updateCancel(params, cb) {
  return {
    type: types.W09F2020_UPDATE_CANCEL,
    params,
    cb
  };
};