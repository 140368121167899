/**
 * @copyright 2019 @ DigiNet
 * @author TRIHAO
 * @create 10/31/2019
 * @Example 
 */

import Api from '../../../services/api';
import {put, cancel, take, takeLatest} from "redux-saga/effects";
import {types} from "../../W0X/W00F0010/W00F0010_actions";
import {delay} from "redux-saga";

export default function W00F0010Sagas() {
    return [
        watchGetCboDatabaseName(),
        watchGetListRoles(),
        watchGetPermissionInfo(),
        watchGetUsersInfo(),
        watchGetCompany(),
        watchGetRoleInfo(),
        watchAddRoleW00F0010(),
        watchEditRoleW00F0010(),
        watchCheckEditRoleW00F0010(),
        watchGetCboModulesW00F0010(),
        watchGetGridRPW00F0010(),
        watchGetCboPermissionW00F0010(),
        watchGetProjectsW00F0010(),
        watchGetSalaryGroupsW00F0010(),
        watchSaveGridRPW00F0010(),
        watchGetOrganiaztionalW00F0010(),
        watchGetCboUserRoles(),
        watchSaveUserRoles(),
        watchGetListUser(),
        watchGetListScreen(),
        watchSavePastesRole(),
        watchLoadCboTransaction(),
    ];
}

export function* getListUser(data) {
    try {
        yield delay(300);
        const response = yield Api.put('/w00f0010/load-users', data.params);
        if (response && response.data) {
            data.cb && data.cb(null, response.data)
        }
        else{
            data.cb && data.cb(response, null)
        }
    }
    catch (e) {
        console.log('get list user error');
    }

}

export function* watchGetListUser() {
    while (true){
        const watcher = yield takeLatest(types.W00F0010_GET_LIST_USER, getListUser);
        yield take(['LOGOUT','NETWORK']);
        yield cancel(watcher);
    }
}

export function* getListScreen(data) {
    try {
        yield delay(300);
        const response = yield Api.put('/w00f0010/load-screen', data.params);
        if (response && response.data) {
            data.cb && data.cb(null, response.data)
        }
        else{
            data.cb && data.cb(response, null)
        }
    }
    catch (e) {
        console.log('get list screen error');
    }

}

export function* watchGetListScreen() {
    while (true){
        const watcher = yield takeLatest(types.W00F0010_GET_LIST_SCREEN, getListScreen);
        yield take(['LOGOUT','NETWORK']);
        yield cancel(watcher);
    }
}

export function* savePastesRole(data) {
    try {
        yield delay(300);
        const response = yield Api.put('/w00f0010/paste-roles', data.params);
        if (response && response.data) {
            data.cb && data.cb(null, response.data)
        }
        else{
            data.cb && data.cb(response, null)
        }
    }
    catch (e) {
        console.log('save paste role error');
    }

}

export function* watchSavePastesRole() {
    while (true){
        const watcher = yield takeLatest(types.W00F0010_SAVE_PASTES_ROLE, savePastesRole);
        yield take(['LOGOUT','NETWORK']);
        yield cancel(watcher);
    }
}

export function* getCboDatabaseName(data) {
    try {
        yield delay(300);
        const response = yield Api.put('/w00f0010/cbo-database-name', data.params);
        if (response && response.data) {
            yield put({type: types.W00F0010_GET_DATABASENAME_SUCCESS, data: response.data});
            data.cb && data.cb(null, response.data)
        }
        else{
            data.cb && data.cb(response, null)
        }
    }
    catch (e) {
        console.log('get databasename combo error');
    }

}

export function* watchGetCboDatabaseName() {
    while (true){
        const watcher = yield takeLatest(types.W00F0010_GET_DATABASENAME, getCboDatabaseName);
        yield take(['LOGOUT','NETWORK']);
        yield cancel(watcher);
    }
}

export function* getListRoles(data) {
    try {
        yield delay(300);
        const response = yield Api.put('/w00f0010/grid-roles', data.params);
        if (response && response.data) {
            yield put({type: types.W00F0010_GET_LISTROLES_SUCCESS, data: response.data});
            data.cb && data.cb(null, response.data)
        }
        else{
            data.cb && data.cb(response, null)
        }
    }
    catch (e) {
        console.log('get list roles error');
    }
}

export function* watchGetListRoles() {
    while (true){
        const watcher = yield takeLatest(types.W00F0010_GET_LISTROLES, getListRoles);
        yield take(['LOGOUT','NETWORK']);
        yield cancel(watcher);
    }
}

//get permission info
export function* getPermissionInfo(data) {
    try {
        yield delay(300);
        const response = yield Api.put('/w00f0010/info-permission', data.params);
        if (response && response.data) {
            data.cb && data.cb(null, response.data)
        }
        else{
            data.cb && data.cb(response, null)
        }
    }
    catch (e) {
        console.log('get permission info error');
    }
}

export function* watchGetPermissionInfo() {
    while (true){
        const watcher = yield takeLatest(types.W00F0010_GET_PERMISSIONINFO, getPermissionInfo);
        yield take(['LOGOUT','NETWORK']);
        yield cancel(watcher);
    }
}

//get users info
export function* getUsersInfo(data) {
    try {
        yield delay(300);
        const response = yield Api.put('/w00f0010/get-list-users-by-role', data.params);
        if (response && response.data) {
            data.cb && data.cb(null, response.data)
        }
        else{
            data.cb && data.cb(response, null)
        }
    }
    catch (e) {
        console.log('get users info error');
    }
}

export function* watchGetUsersInfo() {
    while (true){
        const watcher = yield takeLatest(types.W00F0010_GET_USERSINFO, getUsersInfo);
        yield take(['LOGOUT','NETWORK']);
        yield cancel(watcher);
    }
}

//get company
export function* getCompany(data) {
    try {
        yield delay(300);
        const response = yield Api.put('/W00F0010/get-company', data.params);
        if (response && response.data) {
            yield put({type: types.W00F0010_GET_COMPANY_SUCCESS, data: response.data});
            data.cb && data.cb(null, response.data)
        }
        else{
            data.cb && data.cb(response, null)
        }
    }
    catch (e) {
        console.log('get company error');
    }
}

export function* watchGetCompany() {
    while (true){
        const watcher = yield takeLatest(types.W00F0010_GET_COMPANY, getCompany);
        yield take(['LOGOUT','NETWORK']);
        yield cancel(watcher);
    }
}

//get role info
export function* getRoleInfo(data) {
    try {
        yield delay(300);
        const response = yield Api.put('/W00F0010/get-info-role', data.params);
        if (response && response.data) {
            yield put({type: types.W00F0010_GET_ROLEINFO_SUCCESS, data: response.data});
            data.cb && data.cb(null, response.data)
        }
        else{
            data.cb && data.cb(response, null)
        }
    }
    catch (e) {
        console.log('get company error');
    }
}

export function* watchGetRoleInfo() {
    while (true){
        const watcher = yield takeLatest(types.W00F0010_GET_ROLEINFO, getRoleInfo);
        yield take(['LOGOUT','NETWORK']);
        yield cancel(watcher);
    }
}

//add role
export function* addRoleW00F0010(data) {
    try {
        yield delay(300);
        const response = yield Api.put('/W00F0010/add', data.params);
        if (response && response.data) {
            data.cb && data.cb(null, response.data)
        }
        else{
            data.cb && data.cb(response, null)
        }
    }
    catch (e) {
        console.log('add role error');
    }
}

export function* watchAddRoleW00F0010() {
    while (true){
        const watcher = yield takeLatest(types.W00F0010_ADD_ROLE, addRoleW00F0010);
        yield take(['LOGOUT','NETWORK']);
        yield cancel(watcher);
    }
}

//add role
export function* editRoleW00F0010(data) {
    try {
        yield delay(300);
        const response = yield Api.put('/W00F0010/edit', data.params);
        if (response && response.data) {
            data.cb && data.cb(null, response.data)
        }
        else{
            data.cb && data.cb(response, null)
        }
    }
    catch (e) {
        console.log('edit role error');
    }
}

export function* watchEditRoleW00F0010() {
    while (true){
        const watcher = yield takeLatest(types.W00F0010_EDIT_ROLE, editRoleW00F0010);
        yield take(['LOGOUT','NETWORK']);
        yield cancel(watcher);
    }
}

//check edit role
export function* checkEditRoleW00F0010(data) {
    try {
        yield delay(300);
        const response = yield Api.put('/w00f0010/get-status-roleId', data.params);
        if (response && response.data) {
            data.cb && data.cb(null, response.data)
        }
        else{
            data.cb && data.cb(response, null)
        }
    }
    catch (e) {
        console.log('check edit role error');
    }
}

export function* watchCheckEditRoleW00F0010() {
    while (true){
        const watcher = yield takeLatest(types.W00F0010_CHECK_EDIT_ROLE, checkEditRoleW00F0010);
        yield take(['LOGOUT','NETWORK']);
        yield cancel(watcher);
    }
}

//get modules combo..
export function* getCboModuleseW00F0010(data) {
    try {
        yield delay(300);
        const response = yield Api.put('/w00f0010/get-modules', data.params);
        if (response && response.data) {
            yield put({type: types.W00F0010_GET_MODULES_SUCCESS, data: response.data});
            data.cb && data.cb(null, response.data)
        }
        else{
            data.cb && data.cb(response, null)
        }
    }
    catch (e) {
        console.log('get modules combo error');
    }
}

export function* watchGetCboModulesW00F0010() {
    while (true){
        const watcher = yield takeLatest(types.W00F0010_GET_MODULES, getCboModuleseW00F0010);
        yield take(['LOGOUT','NETWORK']);
        yield cancel(watcher);
    }
}

//get grid roles permission..
export function* getCboPermissionW00F0010(data) {
    try {
        yield delay(300);
        const response = yield Api.put('/w00f0010/get-permission', data.params);
        if (response && response.data) {
            yield put({type: types.W00F0010_GET_PERMISSION_SUCCESS, data: response.data});
            data.cb && data.cb(null, response.data)
        }
        else{
            data.cb && data.cb(response, null)
        }
    }
    catch (e) {
        console.log('get grid role permission error');
    }
}

export function* watchGetCboPermissionW00F0010() {
    while (true){
        const watcher = yield takeLatest(types.W00F0010_GET_PERMISSION, getCboPermissionW00F0010);
        yield take(['LOGOUT','NETWORK']);
        yield cancel(watcher);
    }
}

//get projects..
export function* getProjectsW00F0010(data) {
    try {
        yield put({type: types.W00F0010_GET_PROJECTS_SUCCESS, data: []});
        yield delay(300);
        const response = yield Api.put('/w00f0010/get-project', data.params);
        if (response && response.data) {
            yield put({type: types.W00F0010_GET_PROJECTS_SUCCESS, data: response.data});
            data.cb && data.cb(null, response.data)
        }
        else{
            data.cb && data.cb(response, null)
        }
    }
    catch (e) {
        console.log('get projects error');
    }
}

export function* watchGetProjectsW00F0010() {
    while (true){
        const watcher = yield takeLatest(types.W00F0010_GET_PROJECTS, getProjectsW00F0010);
        yield take(['LOGOUT','NETWORK']);
        yield cancel(watcher);
    }
}

//get salary groups..
export function* getSalaryGroupsW00F0010(data) {
    try {
        yield put({type: types.W00F0010_GET_SALARYGROUPS_SUCCESS, data: []});
        yield delay(300);
        const response = yield Api.put('/w00f0010/get-salary-group', data.params);
        if (response && response.data) {
            yield put({type: types.W00F0010_GET_SALARYGROUPS_SUCCESS, data: response.data});
            data.cb && data.cb(null, response.data)
        }
        else{
            data.cb && data.cb(response, null)
        }
    }
    catch (e) {
        console.log('get salary groups error');
    }
}

export function* watchGetSalaryGroupsW00F0010() {
    while (true){
        const watcher = yield takeLatest(types.W00F0010_GET_SALARYGROUPS, getSalaryGroupsW00F0010);
        yield take(['LOGOUT','NETWORK']);
        yield cancel(watcher);
    }
}

//get organiaztional..
export function* getOrganizationalW00F0010(data) {
    try {
        yield put({type: types.W00F0010_GET_ORGANIZATIONAL_SUCCESS, data: []});
        yield delay(300);
        const response = yield Api.put('/w00f0010/get-structure', data.params);
        if (response && response.data) {
            yield put({type: types.W00F0010_GET_ORGANIZATIONAL_SUCCESS, data: response.data});
            data.cb && data.cb(null, response.data)
        }
        else{
            data.cb && data.cb(response, null)
        }
    }
    catch (e) {
        console.log('get organizational error');
    }
}

export function* watchGetOrganiaztionalW00F0010() {
    while (true){
        const watcher = yield takeLatest(types.W00F0010_GET_ORGANIZATIONAL, getOrganizationalW00F0010);
        yield take(['LOGOUT','NETWORK']);
        yield cancel(watcher);
    }
}

//get grid roles permission..
export function* getGridRPW00F0010(data) {
    try {
        yield delay(300);
        const response = yield Api.put('/w00f0010/grid-permission', data.params);
        if (response && response.data) {
            yield put({type: types.W00F0010_GET_ROLESPERMISSION_SUCCESS, data: response.data});
            data.cb && data.cb(null, response.data)
        }
        else{
            data.cb && data.cb(response, null)
        }
    }
    catch (e) {
        console.log('get grid role permission error');
    }
}

export function* watchGetGridRPW00F0010() {
    while (true){
        const watcher = yield takeLatest(types.W00F0010_GET_ROLESPERMISSION, getGridRPW00F0010);
        yield take(['LOGOUT','NETWORK']);
        yield cancel(watcher);
    }
}

//save grid permission..
export function* saveGridRPW00F0010(data) {
    try {
        yield delay(300);
        const response = yield Api.post('/w00f0010/save-permission', data.params);
        if (response && response.data) {
            data.cb && data.cb(null, response.data)
        }
        else{
            data.cb && data.cb(response, null)
        }
    }
    catch (e) {
        console.log('save grid permission error');
    }
}

export function* watchSaveGridRPW00F0010() {
    while (true){
        const watcher = yield takeLatest(types.W00F0010_SAVE_GRIDRP, saveGridRPW00F0010);
        yield take(['LOGOUT','NETWORK']);
        yield cancel(watcher);
    }
}

//get cbo user roles
export function* getCboUserRoles(data) {
    try {
        yield delay(300);
        const response = yield Api.get('/w00f0010/get-cbo-users-by-role');
        if (response && response.data) {
            yield put({type: types.W00F0010_GET_CBO_USER_ROLES_SUCCESS, data: response.data});
            data.cb && data.cb(null, response.data)
        }
        else{
            data.cb && data.cb(response, null)
        }
    }
    catch (e) {
        console.log('get cbo user roles error');
    }
}

export function* watchGetCboUserRoles() {
    while (true){
        const watcher = yield takeLatest(types.W00F0010_GET_CBO_USER_ROLES, getCboUserRoles);
        yield take(['LOGOUT','NETWORK']);
        yield cancel(watcher);
    }
}


//save user roles
export function* saveUserRolesW00F0010(data) {
    try {
        yield delay(300);
        const response = yield Api.put('/w00f0010/add-user-role', data.params);
        if (response && response.data) {
            data.cb && data.cb(null, response.data)
        }
        else{
            data.cb && data.cb(response, null)
        }
    }
    catch (e) {
        console.log('save user roles error');
    }
}

export function* watchSaveUserRoles() {
    while (true){
        const watcher = yield takeLatest(types.W00F0010_SAVE_USER_ROLES, saveUserRolesW00F0010);
        yield take(['LOGOUT','NETWORK']);
        yield cancel(watcher);
    }
}

//loadCboTransaction
export function* loadCboTransaction(data) {
    try {
        yield delay(300);
        const response = yield Api.put('/w00f0010/Load-cbo-transaction', data.params);
        if (response && response.data) {
            data.cb && data.cb(null, response.data)
        }
        else{
            data.cb && data.cb(response, null)
        }
    }
    catch (e) {
        console.log('load Cbo Transaction error');
    }
}

export function* watchLoadCboTransaction() {
    while (true){
        const watcher = yield takeLatest(types.W00F0010_LOAD_CBO_TRANSACTION, loadCboTransaction);
        yield take(['LOGOUT','NETWORK']);
        yield cancel(watcher);
    }
}