/**
 * @copyright 2019 @ DigiNet
 * @author TRIHAO
 * @create 10/31/2019
 * @Example 
 */

import {cancel, take, takeLatest} from "redux-saga/effects";
import {types} from "../../W0X/W09F0001/W09F0001_actions";
import {delay} from "redux-saga";
import Api from "../../../services/api";

export default function W09F0001Sagas() {
    return [
        watchGetDataAttachments(),
        watchSaveAttachment(),
        watchDeleteAttachment(),
    ];
}

//Get data attachments..
export function* getDataAttachments(data) {
    try {
        yield delay(300);
        const response = yield Api.put('/w09f0001/load-form-data', data.params);
        if (response && response.data) {
            data.cb && data.cb(null, response.data)
        }
        else{
            data.cb && data.cb(response, null)
        }
    }
    catch (e) {
        console.log('get data attachments error');
    }

}

export function* watchGetDataAttachments() {
    while (true){
        const watcher = yield takeLatest(types.W09F0001_GET_DATAATTACHMENTS, getDataAttachments);
        yield take(['LOGOUT','NETWORK']);
        yield cancel(watcher);
    }
}

export function* saveAttachment(data) {
    try {
        yield delay(300);
        const response = yield Api.put('/w17f2500/save-attachment', data.params);
        if (response && response.data) {
            data.cb && data.cb(null, response.data)
        }
        else{
            data.cb && data.cb(response, null)
        }
    }
    catch (e) {
        console.log('save attachment error');
    }

}

export function* watchSaveAttachment() {
    while (true){
        const watcher = yield takeLatest(types.W09F0001_SAVE_ATTACHMENT, saveAttachment);
        yield take(['LOGOUT','NETWORK']);
        yield cancel(watcher);
    }
}

export function* deleteAttachment(data) {
    try {
        yield delay(300);
        const response = yield Api.put('/w09f0001/delete-attachment', data.params);
        if (response && response.data) {
            data.cb && data.cb(null, response.data)
        }
        else{
            data.cb && data.cb(response, null)
        }
    }
    catch (e) {
        console.log('delete attachment error');
    }

}

export function* watchDeleteAttachment() {
    while (true){
        const watcher = yield takeLatest(types.W09F0001_DELETE_ATTACHMENT, deleteAttachment);
        yield take(['LOGOUT','NETWORK']);
        yield cancel(watcher);
    }
}